import { useEffect, useState } from "react";
import styles from './App.module.css'
import artScene from './Images/andreaArtScene.png'
import artSceneMobile from './Images/andreaArtSceneMobile.png'
import emailMe from './Images/emailMeNote.png'
import linkedInNote from './Images/linkedInNote.png'
import githubNote from './Images/githubNote.png'
import { ContactMeDialog } from './Components'

// ANDI - todo set up a service to send me the ip address and email of the person who went to my website
// Clean up and refine the email me modal, correct colors etc. 
// Remove any old and unused code.
// Make sure mobile and very large desktop scaling is working
// set up dynamic importing of images
// refine text in the text area, it's not finished

// DONE
// Remove all unused images and rename any that need it
// delete unused fonts


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const [isOpen, setIsOpen] = useState(false)
  const { width } = useWindowDimensions();
  const isMobile = width < 1080

  return (
    <>
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          {isMobile && <div className={styles.title}> ANDREA CARLSTROM </div>}
        </div>
        <div className={styles.imageContainer}>
          {isMobile ? 
            <img 
              src={artSceneMobile} 
              alt="artSceneMobile" 
            />
          :
            <img
              src={artScene} 
              alt="artScene"
            />
          }
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            {!isMobile && <div className={styles.title}> ANDREA CARLSTROM </div>}
            <div className={styles.paragraph}>
               Hello there! I'm Andrea, a user-focused Software Engineer with 4+ years of experience in the field of engineering. In that time, I've honed my skills in creating robust, user-centric full stack applications in React, Typescript, Node.js and C#. If you'd like to connect feel free to reach out by contacting me through one of the links below!
            </div>
            {/* <div className={styles.paragraph}>
              Let's connect! Click on one of the notes below so we can get in touch!
            </div> */}
          </div>
          <div className={styles.contactMeContainer}>
            <div 
              className={styles.noteContainer} 
              onClick={() => setIsOpen((s) => !s)}
            > 
              <img 
                src={emailMe} 
                alt="emailMe" 
                className={styles.note}
              />
            </div> 
            <div 
              className={styles.noteContainer} 
              onClick={() => window.open('https://www.linkedin.com/in/andrea-carlstrom/')}
            > 
              <img 
                src={linkedInNote} 
                alt="linkedInNote" 
                className={styles.note}
              />
            </div> 
            <div 
              className={styles.noteContainer} 
              onClick={() => window.open('https://github.com/andicarlstrom')} 
            > 
              <img 
                src={githubNote} 
                alt="githubNote" 
                className={styles.note}
              />
            </div> 
          </div>
        </div>
      </div>

      <ContactMeDialog 
        isOpen={isOpen} 
        setIsOpen={() => setIsOpen((s) => !s)} 
      />
    </>
  );
}

export default App; 
