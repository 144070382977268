import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import styles from './ContactMeDialog.module.css'
import axios from 'axios'
import { Formik } from 'formik';
import classnames from 'classnames'

export const ContactMeDialog = ({ isOpen, setIsOpen }) => {

  const onSendEmail = (values) => {
    axios({
      method: 'post',
      url: '/send-email',
      data: values
    })
    .then(res => { 
      setIsOpen();
      return Promise.resolve(res)
    })
    .catch((error) => {
      console.log(error)
      return Promise.reject(error)
    })
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <div className={styles.title}>CONTACT ME</div>
        <Formik
          initialValues={{ 
            firstName: '',
            lastName: '',
            email: '', 
            message: '' 
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if(!values.firstName) {
              errors.firstName = 'Required'
            }

            if(!values.lastName) {
              errors.lastName = 'Required'
            }

            if(!values.message) {
              errors.message = 'Required'
            }
            
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSendEmail({
              name: values.firstName + ' ' + values.lastName,
              email: values.email,
              message: values.message
            })
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                  <div className={styles.content}>
                    <TextField
                      className={classnames(styles.dialog, styles.test)}
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="First name"
                      value={values.firstName}
                      onChange={handleChange}
                      type='text'
                      margin="dense"
                      variant="standard"
                    />
                    {errors.firstName && <div className={styles.error}>{errors.firstName}</div>}
                    <TextField
                      fullWidth
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      value={values.lastName}
                      onChange={handleChange}
                      type='text'
                      margin="dense"
                      variant="standard"
                    />
                    {errors.lastName && <div className={styles.error}>{errors.lastName}</div>}
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      type='email'
                      error={errors.email}
                      margin="dense"
                      variant="standard"
                    />
                    {errors.email && <div className={styles.error}>{errors.email}</div>}
                    <TextField
                      fullWidth
                      id="message"
                      name="message"
                      label="Message"
                      value={values.message}
                      onChange={handleChange}
                      type='textarea'
                      margin="dense"
                      variant="standard"
                      multiline
                    />
                    {errors.message && <div className={styles.error}>{errors.message}</div>}
                  </div>
                  <DialogActions>
                    <Button variant="contained" onClick={setIsOpen} className={styles.button}>
                      Cancel
                    </Button>
                    <Button variant="contained" type='submit' className={styles.button}>
                      Send
                    </Button>
                  </DialogActions>
                </form>
          )}}
        </Formik>
      </Dialog>
    </div>
  );
}